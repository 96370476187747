<template>
    <div id="barra-fija">
        <a href="/logout/" class="btn btn-primary pull-right">Cerrar Sesion</a>
        <div id="menu-principal">
            <form method="post" class="form-inline">
                <a href="/" id="logo-taco"></a>&nbsp;
                <router-link :to="{ name: 'home' }" class="btn btn-primary">Inicio</router-link>&nbsp;
                <span v-if="showBackButton">
                    <router-link :to="{ name: 'edit-order', id}" class="btn btn-primary">Volver</router-link>&nbsp;
                </span>
                <input type="button" class="btn btn-primary" @click="saveAndExport" value="Exportar" />&nbsp;
                <input type="button" class="btn btn-primary" @click="saveAndExportWithProvider" value="Exportar con Proveedor" />&nbsp;
                <input type="button" class="btn btn-primary" @click="saveExportAndSend" value="Enviar Pedido" />&nbsp;
                <input type="button" class="btn btn-primary" @click="totals" value="Totales" />&nbsp;
                <input type="text" name="analisis" id="analisis" class="form-control" v-model="title" />&nbsp;
                <input type="button" name="guardar" id="guardar" @click="save" class="btn btn-primary" value="Guardar" />&nbsp;
                <input type="button" class="btn btn-primary" @click="autoCompletar" value="Autocompletar" title="No se respetarán los valores minimos" />
                &nbsp;
                <div class="clear"></div>
            </form>
        <div class="clear"></div>
        </div>
        <!-- <div id="clientes-activos">
        <div>C.A</div>
        </div> -->
    </div>
</template>

<script>
import { ref } from 'vue';
// import axios from 'axios';
import { useRoute } from 'vue-router'

export default {

    props: {"analisis": String},

    setup(props, context) {

        const route = useRoute();
        console.log( 'route: ', route.params.id )
        var showBackButton = false
        if( parseInt(route.params.id) ){
            showBackButton = true
        }
// const showBackButton = () => {
//             if(typeof route.params.id !== 'undefined'){
//                 return true
//             }
//             return false
//         }

        const title = ref(props.analisis);

        const totals = () => {
            // console.log('totales')
            context.emit("totals")
        }

        const save = () => {
            // console.log('save 1', title.value)
            context.emit("save", title.value)
        }

        const autoCompletar = () => {
            // console.log('autoCompletar')
            context.emit("autoCompletar")
        }

        const saveAndExport = () => {
            context.emit("saveAndExport")
        }

        const saveAndExportWithProvider = () => {
           context.emit("saveAndExportWithProvider") 
        }

        const saveExportAndSend = () => {
           context.emit("saveExportAndSend") 
        }

        return {
            totals,
            save,
            autoCompletar,
            saveAndExport,
            saveAndExportWithProvider,
            saveExportAndSend,
            showBackButton,
            title,
            id: route.params.id,
        }
    },
}
</script>
