<template>
  <div>
    <main-header
      :analisis="analisisName" 
      @save="save" 
      @totals="totals" 
      @autoCompletar="autoCompletar" 
      @saveAndExport="saveAndExport"
      @saveAndExportWithProvider="saveAndExportWithProvider"
      @saveExportAndSend="saveExportAndSend"
      :key="'analisisName-'+analisisName"
    />
    <table class="table contador table-bordered table-responsive" id="cabecera-flotante">
      <TableHeader
        :usuario="spreadsheet.usuario"
        :desde="spreadsheet.desde"	
        :hasta="spreadsheet.hasta"	
        :pedir_pico_a="spreadsheet.pedir_pico_a"
        :pedir_pico_b="spreadsheet.pedir_pico_b"	
        :pedir_pico_c="spreadsheet.pedir_pico_c"
        :pedir_mdp_a="spreadsheet.pedir_mdp_a"
        :pedir_mdp_b="spreadsheet.pedir_mdp_b"
        :pedir_mdp_c="spreadsheet.pedir_mdp_c"
        :pedir_ba_a="spreadsheet.pedir_ba_a"
        :pedir_ba_b="spreadsheet.pedir_ba_b"
        :pedir_ba_c="spreadsheet.pedir_ba_c"
        :pedido_rapido="spreadsheet.pedido_rapido"
        :comprar_transferir="spreadsheet.comprar_transferir"
        :mostrar_criticos="spreadsheet.mostrar_criticos"
        :mostrar_nuevos="spreadsheet.mostrar_nuevos"
        :mostrar_alta="spreadsheet.mostrar_alta"
        :mostrar_media="spreadsheet.mostrar_media"
        :mostrar_baja="spreadsheet.mostrar_baja"
        :mostrar_pico="spreadsheet.mostrar_pico"
        :mostrar_mdp="spreadsheet.mostrar_mdp"
        :mostrar_ba="spreadsheet.mostrar_ba"
        :orden="spreadsheet.orden"
        :marcas="spreadsheet.marcas"    
        :meses_a_analizar="spreadsheet.meses_a_analizar"
        :marcas_a_analizar="spreadsheet.marcas_a_analizar"    
        :coeficientes_a_analizar="spreadsheet.coeficientes_a_analizar"
        :promedio_total_u="spreadsheet.promedio_total_u"
        :promedio_total_m="spreadsheet.promedio_total_m"
        :stock_total_u="spreadsheet.stock_total_u"
        :stock_total_m="spreadsheet.stock_total_m"
        :pendientes_total_u="spreadsheet.pendientes_total_u"
        :pendientes_total_m="spreadsheet.pendientes_total_m"
        :recomendado_total_u="spreadsheet.recomendado_total_u"
        :recomendado_total_m="spreadsheet.recomendado_total_m"
        :p_total_u="spreadsheet.p_total_u"
        :p_total_m="spreadsheet.p_total_m"
        :promedio_total_eq_u="spreadsheet.promedio_total_eq_u"
        :promedio_total_eq_m="spreadsheet.promedio_total_eq_m"
        :total_pendiente_eq_u="spreadsheet.total_pendiente_eq_u"
        :total_pendiente_eq_m="spreadsheet.total_pendiente_eq_m"
        :recom_mdp_pico="spreadsheet.recom_mdp_pico"
        :recom_ba_pico="spreadsheet.recom_ba_pico"
        :recom_pico_mdp="spreadsheet.recom_pico_mdp"
        :recom_ba_mdp="spreadsheet.recom_ba_mdp"
        :recom_pico_ba="spreadsheet.recom_pico_ba"
        :recom_mdp_ba="spreadsheet.recom_mdp_ba"
        :mdp_pico="spreadsheet.mdp_pico"
        :ba_pico="spreadsheet.ba_pico"
        :pico_mdp="spreadsheet.pico_mdp"
        :ba_mdp="spreadsheet.ba_mdp"
        :pico_ba="spreadsheet.pico_ba"
        :mdp_ba="spreadsheet.mdp_ba"
        :mdp_pico_m="spreadsheet.mdp_pico_m"
        :ba_pico_m="spreadsheet.ba_pico_m"
        :pico_mdp_m="spreadsheet.pico_mdp_m"
        :ba_mdp_m="spreadsheet.ba_mdp_m"
        :pico_ba_m="spreadsheet.pico_ba_m"
        :mdp_ba_m="spreadsheet.mdp_ba_m"
        :promedio_pico="spreadsheet.promedio_pico"
        :promedio_mdp="spreadsheet.promedio_mdp"
        :promedio_ba="spreadsheet.promedio_ba"
        :promedio_pico_m="spreadsheet.promedio_pico_m"
        :promedio_mdp_m="spreadsheet.promedio_mdp_m"
        :promedio_ba_m="spreadsheet.promedio_ba_m"
        :stock_pico="spreadsheet.stock_pico"
        :stock_mdp="spreadsheet.stock_mdp"
        :stock_ba="spreadsheet.stock_ba"
        :stock_pico_m="spreadsheet.stock_pico_m"
        :stock_mdp_m="spreadsheet.stock_mdp_m"
        :stock_ba_m="spreadsheet.stock_ba_m"
        :pendientes_pico="spreadsheet.pendientes_pico"
        :pendientes_mdp="spreadsheet.pendientes_mdp"
        :pendientes_ba="spreadsheet.pendientes_ba"
        :pendientes_pico_m="spreadsheet.pendientes_pico_m"
        :pendientes_mdp_m="spreadsheet.pendientes_mdp_m"
        :pendientes_ba_m="spreadsheet.pendientes_ba_m"
        :recomendado_pico="spreadsheet.recomendado_pico"
        :recomendado_mdp="spreadsheet.recomendado_mdp"
        :recomendado_ba="spreadsheet.recomendado_ba"
        :recomendado_pico_m="spreadsheet.recomendado_pico_m"
        :recomendado_mdp_m="spreadsheet.recomendado_mdp_m"
        :recomendado_ba_m="spreadsheet.recomendado_ba_m"
        :pedido_pico="spreadsheet.pedido_pico"
        :pedido_pico_m="spreadsheet.pedido_pico_m"
        :pedido_mdp="spreadsheet.pedido_mdp"
        :pedido_mdp_m="spreadsheet.pedido_mdp_m"
        :pedido_ba="spreadsheet.pedido_ba"
        :pedido_ba_m="spreadsheet.pedido_ba_m"
        :fecha="spreadsheet.fecha"    
        :fecha_mod="spreadsheet.fecha_mod"    
        :exportado="spreadsheet.exportado"
        :showTotals="showTotals"
      />
    </table>
    <table class="table contador table-bordered table-responsive" id="tabla-principal">
      <TableHeader
        :usuario="spreadsheet.usuario"
        :desde="spreadsheet.desde"	
        :hasta="spreadsheet.hasta"	
        :pedir_pico_a="spreadsheet.pedir_pico_a"
        :pedir_pico_b="spreadsheet.pedir_pico_b"	
        :pedir_pico_c="spreadsheet.pedir_pico_c"
        :pedir_mdp_a="spreadsheet.pedir_mdp_a"
        :pedir_mdp_b="spreadsheet.pedir_mdp_b"
        :pedir_mdp_c="spreadsheet.pedir_mdp_c"
        :pedir_ba_a="spreadsheet.pedir_ba_a"
        :pedir_ba_b="spreadsheet.pedir_ba_b"
        :pedir_ba_c="spreadsheet.pedir_ba_c"
        :pedido_rapido="spreadsheet.pedido_rapido"
        :comprar_transferir="spreadsheet.comprar_transferir"
        :mostrar_criticos="spreadsheet.mostrar_criticos"
        :mostrar_nuevos="spreadsheet.mostrar_nuevos"
        :mostrar_alta="spreadsheet.mostrar_alta"
        :mostrar_media="spreadsheet.mostrar_media"
        :mostrar_baja="spreadsheet.mostrar_baja"
        :mostrar_pico="spreadsheet.mostrar_pico"
        :mostrar_mdp="spreadsheet.mostrar_mdp"
        :mostrar_ba="spreadsheet.mostrar_ba"
        :orden="spreadsheet.orden"
        :marcas="spreadsheet.marcas"    
        :meses_a_analizar="spreadsheet.meses_a_analizar"
        :marcas_a_analizar="spreadsheet.marcas_a_analizar"    
        :coeficientes_a_analizar="spreadsheet.coeficientes_a_analizar"
        :promedio_total_u="spreadsheet.promedio_total_u"
        :promedio_total_m="spreadsheet.promedio_total_m"
        :stock_total_u="spreadsheet.stock_total_u"
        :stock_total_m="spreadsheet.stock_total_m"
        :pendientes_total_u="spreadsheet.pendientes_total_u"
        :pendientes_total_m="spreadsheet.pendientes_total_m"
        :recomendado_total_u="spreadsheet.recomendado_total_u"
        :recomendado_total_m="spreadsheet.recomendado_total_m"
        :p_total_u="spreadsheet.p_total_u"
        :p_total_m="spreadsheet.p_total_m"
        :promedio_total_eq_u="spreadsheet.promedio_total_eq_u"
        :promedio_total_eq_m="spreadsheet.promedio_total_eq_m"
        :total_pendiente_eq_u="spreadsheet.total_pendiente_eq_u"
        :total_pendiente_eq_m="spreadsheet.total_pendiente_eq_m"
        :recom_mdp_pico="spreadsheet.recom_mdp_pico"
        :recom_ba_pico="spreadsheet.recom_ba_pico"
        :recom_pico_mdp="spreadsheet.recom_pico_mdp"
        :recom_ba_mdp="spreadsheet.recom_ba_mdp"
        :recom_pico_ba="spreadsheet.recom_pico_ba"
        :recom_mdp_ba="spreadsheet.recom_mdp_ba"
        :mdp_pico="spreadsheet.mdp_pico"
        :ba_pico="spreadsheet.ba_pico"
        :pico_mdp="spreadsheet.pico_mdp"
        :ba_mdp="spreadsheet.ba_mdp"
        :pico_ba="spreadsheet.pico_ba"
        :mdp_ba="spreadsheet.mdp_ba"
        :mdp_pico_m="spreadsheet.mdp_pico_m"
        :ba_pico_m="spreadsheet.ba_pico_m"
        :pico_mdp_m="spreadsheet.pico_mdp_m"
        :ba_mdp_m="spreadsheet.ba_mdp_m"
        :pico_ba_m="spreadsheet.pico_ba_m"
        :mdp_ba_m="spreadsheet.mdp_ba_m"
        :promedio_pico="spreadsheet.promedio_pico"
        :promedio_mdp="spreadsheet.promedio_mdp"
        :promedio_ba="spreadsheet.promedio_ba"
        :promedio_pico_m="spreadsheet.promedio_pico_m"
        :promedio_mdp_m="spreadsheet.promedio_mdp_m"
        :promedio_ba_m="spreadsheet.promedio_ba_m"
        :stock_pico="spreadsheet.stock_pico"
        :stock_mdp="spreadsheet.stock_mdp"
        :stock_ba="spreadsheet.stock_ba"
        :stock_pico_m="spreadsheet.stock_pico_m"
        :stock_mdp_m="spreadsheet.stock_mdp_m"
        :stock_ba_m="spreadsheet.stock_ba_m"
        :pendientes_pico="spreadsheet.pendientes_pico"
        :pendientes_mdp="spreadsheet.pendientes_mdp"
        :pendientes_ba="spreadsheet.pendientes_ba"
        :pendientes_pico_m="spreadsheet.pendientes_pico_m"
        :pendientes_mdp_m="spreadsheet.pendientes_mdp_m"
        :pendientes_ba_m="spreadsheet.pendientes_ba_m"
        :recomendado_pico="spreadsheet.recomendado_pico"
        :recomendado_mdp="spreadsheet.recomendado_mdp"
        :recomendado_ba="spreadsheet.recomendado_ba"
        :recomendado_pico_m="spreadsheet.recomendado_pico_m"
        :recomendado_mdp_m="spreadsheet.recomendado_mdp_m"
        :recomendado_ba_m="spreadsheet.recomendado_ba_m"
        :pedido_pico="spreadsheet.pedido_pico"
        :pedido_pico_m="spreadsheet.pedido_pico_m"
        :pedido_mdp="spreadsheet.pedido_mdp"
        :pedido_mdp_m="spreadsheet.pedido_mdp_m"
        :pedido_ba="spreadsheet.pedido_ba"
        :pedido_ba_m="spreadsheet.pedido_ba_m"
        :fecha="spreadsheet.fecha"    
        :fecha_mod="spreadsheet.fecha_mod"    
        :exportado="spreadsheet.exportado"
        :showTotals="true"
      />
			<tbody :class="{ alerta_ventas_irregulares: false, scrollable: true }">
        <template v-for="(line, index) in body" :key="index">
          <template
            v-if="
              (
                spreadsheet.pedido_rapido == 1 &&
                (line.recomended_pico>0 || line.recomended_mdp>0 || line.recomended_ba>0)
              ) ||
              (
                spreadsheet.pedido_rapido == 0 &&
                (
                  (spreadsheet.mostrar_nuevos && line.nuevo) || 
                  (spreadsheet.mostrar_criticos && line.critico) ||
                  (spreadsheet.mostrar_alta && line.group.toUpperCase() == 'A') ||
                  (spreadsheet.mostrar_media && line.group.toUpperCase() == 'B') ||
                  (spreadsheet.mostrar_baja && line.group.toUpperCase() == 'C')
                )
              )
            ">
            <tr :class="{la:true, an:true, article:true, rotacion_baja: line.group == 'c' }"><!-- falta la clase de rotacion -->
              <td :rowspan="1+line.providers.length + line.equivalents.length" :class="{estado_critico: line.critico, articulo_nuevo: line.nuevo}">
                <p>{{index+1}}</p>
                <pending-products :idArticulo="line.id_articulo" :codigoParticular="line.codigo_particular" />
                <p class="text-muted">(Lote mín {{line.pedir_minimo}})</p>
                <p class="text-muted">(Múltiplo lote {{line.pedir_multiplo}})</p>
                <p class="text-muted" v-if="line.nuevo">(Nuevo)</p>
                <p class="text-muted" v-if="line.critico">(Crítico)</p>
                <p class="text-muted">{{line.group.toUpperCase() }}</p>
              </td>
              <td>
                <p class="crop-3">{{line.nombre_articulo}}</p>
              </td>
              <td class="num">
                $ {{ priceFormat(line.precio_lista) }}
                <sales-details :idArticulo="line.id_articulo" />
                <!-- <p>
                  <input
                    type="button" 
                    name="ver-detalles-articulo[]"
                    class="ver-detalles-articulo btn btn-primary btn-sm"
                    @click="getDetails(line.id_articulo)"
                    value="Detalles"
                  />
                </p> -->
              </td>

              <td class="num str li-promedio_pico_u" v-if="spreadsheet.mostrar_pico == 1">
                {{ roundUnits(line.promedio_ventas_pico_unidades) }}
              </td>
              <td class="num" v-if="spreadsheet.mostrar_pico == 1">
                <strong class="li-stock_pico_u">{{ roundUnits(line.stock_pico_unidades) }}</strong><br />
                {{ stockUnits( divide(line.stock_pico_unidades, line.promedio_ventas_pico_unidades) ) }}
              </td>
              <td class="num str li-pendiente_pico" v-if="spreadsheet.mostrar_pico == 1">
                {{ roundUnits(line.stock_pendiente_pico_unidades) }}<br />
                {{ stockUnits( divide(line.stock_pendiente_pico_unidades, line.promedio_ventas_pico_unidades) ) }}
              </td>

              <td class="num str li-promedio_mdp_u" v-if="spreadsheet.mostrar_mdp == 1">
                {{ roundUnits(line.promedio_ventas_mdp_unidades) }}
              </td>
              <td class="num" v-if="spreadsheet.mostrar_mdp == 1">
                <strong class="li-stock_mdp_u">{{ roundUnits(line.stock_mdp_unidades) }}</strong><br />
                {{ stockUnits( divide(line.stock_mdp_unidades, line.promedio_ventas_mdp_unidades) ) }}
              </td>
              <td class="num str li-pendiente_mdp" v-if="spreadsheet.mostrar_mdp == 1">
                {{ roundUnits(line.stock_pendiente_mdp_unidades) }}<br />
                {{ stockUnits( divide(line.stock_pendiente_mdp_unidades, line.promedio_ventas_mdp_unidades) ) }}
              </td>

              <td class="num str li-promedio_ba_u" v-if="spreadsheet.mostrar_ba == 1">
                {{ roundUnits(line.promedio_ventas_ba_unidades) }}
              </td>
              <td class="num" v-if="spreadsheet.mostrar_ba == 1">
                <strong class="li-stock_ba_u">{{ roundUnits(line.stock_ba_unidades) }}</strong><br />
                {{ stockUnits( divide(line.stock_ba_unidades, line.promedio_ventas_ba_unidades) ) }}
              </td>
              <td class="num str li-pendiente_ba" v-if="spreadsheet.mostrar_ba == 1">
                {{ roundUnits(line.stock_pendiente_ba_unidades) }}<br />
                {{ stockUnits( divide(line.stock_pendiente_ba_unidades, line.promedio_ventas_ba_unidades) ) }}
              </td>
            </tr>

            <!-- PROVEEDORES -->
            <tr
              v-for="(provider, indexProvider) in line.providers" 
              :key="indexProvider" 
              :class="{
                ld: indexProvider == (line.providers.length-1) && line.equivalents.length > 0,
                ld2: indexProvider == (line.providers.length-1) && line.equivalents.length == 0,
                provider: true
              }"
            >
              <td>
                <p class="crop-3">{{provider.razonsocial}}</p>
              </td>
              <td class="num">
                ${{ priceFormat(provider.precio * provider.coeficiente_precio ) }}
              </td>

              <td class="num" v-if="spreadsheet.mostrar_pico == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.pedir_pico"
                  :show="spreadsheet.comprar_transferir"
                  @input="provider.pedir_pico = parseInt($event)"
                />
                <p class="recomended" v-if="line.recomended_pico && indexProvider == 0 && spreadsheet.comprar_transferir == 1">{{line.recomended_pico}}</p>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_pico == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.mdp_pico"
                  :show="indexProvider == 0"
                  @input="provider.mdp_pico = parseInt($event)"
                />
                <strong v-if="indexProvider == 0 && line.transferir['mdp_pico'] > 0">{{line.transferir['mdp_pico']}}&nbsp;</strong>
                <small v-if="indexProvider == 0">De MdP</small>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_pico == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.ba_pico"
                  :show="indexProvider == 0"
                  @input="provider.ba_pico = parseInt($event)"
                />
                <strong v-if="indexProvider == 0 && line.transferir['ba_pico'] > 0">{{line.transferir['ba_pico']}}&nbsp;</strong>
                <small v-if="indexProvider == 0">De BA</small>
              </td>

              <td class="num" v-if="spreadsheet.mostrar_mdp == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.pedir_mdp"
                  :show="spreadsheet.comprar_transferir == 1"
                  @input="provider.pedir_mdp = parseInt($event)"
                />
                <p class="recomended" v-if="line.recomended_mdp && indexProvider == 0 && spreadsheet.comprar_transferir == 1">{{line.recomended_mdp}}</p>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_mdp == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.pico_mdp"
                  :show="indexProvider == 0"
                  @input="provider.pico_mdp = parseInt($event)"
                />
                <strong v-if="indexProvider == 0 && line.transferir['pico_mdp'] > 0">{{line.transferir['pico_mdp']}}&nbsp;</strong>
                <small v-if="indexProvider == 0">De Pico</small>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_mdp == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.ba_mdp"
                  :show="indexProvider == 0"
                  @input="provider.ba_mdp = parseInt($event)"
                />
                <strong v-if="indexProvider == 0 && line.transferir['ba_mdp'] > 0">{{line.transferir['ba_mdp']}}&nbsp;</strong>
                <small v-if="indexProvider == 0">De BA</small>
              </td>

              <td class="num" v-if="spreadsheet.mostrar_ba == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.pedir_ba"
                  :show="spreadsheet.comprar_transferir == 1"
                  @input="provider.pedir_ba = parseInt($event)"
                />
                <p class="recomended" v-if="line.recomended_ba && indexProvider == 0 && spreadsheet.comprar_transferir == 1">{{line.recomended_ba}}</p>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_ba == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.pico_ba"
                  :show="indexProvider == 0"
                  @input="provider.pico_ba = parseInt($event)"
                />
                <strong v-if="indexProvider == 0 && line.transferir['pico_ba'] > 0">{{line.transferir['pico_ba']}}&nbsp;</strong>
                <small v-if="indexProvider == 0">De Pico</small>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_ba == 1">
                <fake-input
                  :step="line.pedir_multiplo"
                  :value="provider.mdp_ba"
                  :show="indexProvider == 0"
                  @input="provider.mdp_ba = parseInt($event)"
                />
                <strong v-if="indexProvider == 0 && line.transferir['mdp_ba'] > 0">{{line.transferir['mdp_ba']}}&nbsp;</strong>
                <small v-if="indexProvider == 0">De MdP</small>
              </td>
            </tr>
            
            <!-- EQUIVALENCIAS -->
            <tr v-for="(equivalent, indexEquivalents) in line.equivalents" :key="indexEquivalents" :class="{ ld2: indexEquivalents == (line.equivalents.length-1), equivalent: true }">
              <td>
                <strong>{{equivalent.codigo_particular}}</strong> - <small>{{equivalent.razonsocial}}</small>
              </td>
              <td class="num">
                <small>${{ priceFormat(equivalent.precio * equivalent.coeficiente_precio ) }}</small>
              </td>

              <td class="num flex-cell" v-if="spreadsheet.mostrar_pico == 1">
                <div class="col1">
                  <small class="strong">{{ roundFormat(equivalent.average_pico) }}</small>
                </div>
                <div class="col2">
                  <fake-input
                    :step="line.pedir_multiplo"
                    :value="equivalent.pedir_pico"
                    :show="true"
                    @input="equivalent.pedir_pico = parseInt($event)"
                  />
                </div>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_pico == 1">
                <small>{{ equivalent.stock.pico }}</small>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_pico == 1">
                <small>{{ equivalent.pendiente.pico }}</small>
              </td>

              <td class="num flex-cell" v-if="spreadsheet.mostrar_mdp == 1">
                <div class="col1">
                  <small class="strong">{{ roundFormat(equivalent.average_mdp) }}</small>
                </div>
                <div class="col2">
                  <fake-input
                    :step="line.pedir_multiplo"
                    :value="equivalent.pedir_mdp"
                    :show="true"
                    @input="equivalent.pedir_mdp = parseInt($event)"
                  />
                </div>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_mdp == 1">
                <small>{{ equivalent.stock.mdp }}</small>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_mdp == 1">
                <small>{{ equivalent.pendiente.mdp }}</small>
              </td>

              <td class="num flex-cell" v-if="spreadsheet.mostrar_ba == 1">
                <div class="col1">
                  <small class="strong">{{ roundFormat(equivalent.average_ba) }}</small>
                </div>
                <div class="col2">
                  <fake-input
                    :step="line.pedir_multiplo"
                    :value="equivalent.pedir_ba"
                    :show="true"
                    @input="equivalent.pedir_ba = parseInt($event)"
                  />
                </div>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_ba == 1">
                <small>{{ equivalent.stock.ba }}</small>
              </td>
              <td class="num" v-if="spreadsheet.mostrar_ba == 1">
                <small>{{ equivalent.pendiente.ba }}</small>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>


<script setup>
import { ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router'
import TableHeader from '@/components/TableHeader'
import axios from 'axios';
import MainHeader from '@/components/MainHeader';
import SalesDetails from '@/components/SalesDetails';
import { appState } from "@/components/appState.js";
import PendingProducts from '@/components/PendingProducts.vue';
import FakeInput from '@/components/FakeInput.vue';

// import SalesDetails from '../components/SalesDetails.vue';

appState.startWaiting();
const route = useRoute();  
const id = route.params.id;
// console.log(id)
const spreadsheet = reactive({});
const analisisName = ref('');
const body = ref([]);
const showTotals = ref(true);

const priceFormat = (number) => {
  number = Math.round(number);
  return number.toLocaleString();
}

const roundFormat = (number) => {
  return Math.round(number*10)/10;
}

const roundUnits = (number) => {
  number = Math.round(100 * number) / 100;
  return number;
}

const roundMonths = (number) => {
  number = Math.round(10 * number) / 10;
  return number;
}

const stockUnits = (number) => {
  if (number >= 999 ) {
    return 'Sobra stock';
  }
  if (number < 0 ) {
    number = 0;
  }
  return '(' + roundMonths(number) + ')';
}

const divide = (number, divisor) => {
  if( divisor == 0 && number > 0 ){
    return 999;
  }
  if( divisor == 0 && number == 0 ){
    return 0;
  }
  return number / divisor;
}

// const checked = (e) => {
//   // console.log('checked');
//   // console.log(e);
//   e.classList.add("your-another-class");
// }

// const validate = () => {
//   // console.log('validate');
//   // console.log(e.value);
// }

const totals = () => {
  // console.log('totals');
  showTotals.value = !showTotals.value;
}

const save = async (title) => {
  appState.startWaiting();
  // console.log('save 2', title);
  // console.log(body.value);

  var newSpreadsheet = spreadsheet;
  newSpreadsheet.body = body.value;
  newSpreadsheet.analisis = title;

  console.log('new: ', newSpreadsheet);

  var orderSaved = await axios.patch(`${process.env.VUE_APP_API}orders/${id}`, newSpreadsheet)
    .then( (response) => {
      console.log(response);
      appState.stopWaiting();
    })
    .catch(error => {
      console.error("Error guardando los datos", error.message);
      appState.stopWaiting();
    });
  console.log('order saved: ', orderSaved);
}

const autoCompletar = () => {
  appState.startWaiting();

  //console.log(body.value)
  for (const key in body.value) {
    //console.log(body.value[key])
    body.value[key].recomended_pico > 0 ? body.value[key].providers[0].pedir_pico = body.value[key].recomended_pico : '';
    body.value[key].recomended_mdp > 0 ? body.value[key].providers[0].pedir_mdp = body.value[key].recomended_mdp : '';
    body.value[key].recomended_ba > 0 ? body.value[key].providers[0].pedir_ba = body.value[key].recomended_ba : '';
    // console.log( body.value[key].providers[0].pedir_pico )
    // const element = body[key];
  }
  appState.stopWaiting();
}

const saveAndExport = () => {
  // console.log('inicio guardado')
  save(analisisName.value)
  // console.log('guardado: ' + id)
  setTimeout( ()=> {
    var download = axios.get(`${process.env.VUE_APP_API}export/${id}`, { responseType: 'blob' })
      .then(response => {
          const blob = new Blob([response.data], { type: 'application/xml' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = analisisName.value + '.zip'
          link.click()
          URL.revokeObjectURL(link.href)
      }).catch(console.error)

    // var download = axios.get(`${process.env.VUE_APP_API}export/${id}`)
    //   .then(response => {
    //       console.log(response)
    //   }).catch(console.error)

    console.log(download);
    console.log('terminado');
  }, 3500)
}

const saveAndExportWithProvider = () => {
  // console.log('inicio guardado')
  save(analisisName.value)
  // console.log('guardado: ' + id)
  setTimeout( ()=> {
    var download = axios.get(`${process.env.VUE_APP_API}export/provider/${id}`, { responseType: 'blob' })
      .then(response => {
          const blob = new Blob([response.data], { type: 'application/xml' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = analisisName.value + '.zip'
          link.click()
          URL.revokeObjectURL(link.href)
      }).catch(console.error)

    console.log(download);
    console.log('terminado');
  }, 2000)
}

const saveExportAndSend = () => {
  // console.log('inicio guardado')
  save(analisisName.value)
  // console.log('guardado: ' + id)
  setTimeout( ()=> {
    var download = axios.get(`${process.env.VUE_APP_API}export/transfer/${id}`, { responseType: 'blob' })
      .then(response => {
          const blob = new Blob([response.data], { type: 'application/xml' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = analisisName.value + '.zip'
          link.click()
          URL.revokeObjectURL(link.href)
      }).catch(console.error)

    console.log(download);
    console.log('terminado');
  }, 2000)
}

const setPedido = () => {

  // PICO
  spreadsheet.pedido_pico = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pedir_pico || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.pedido_pico_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pedir_pico * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  // MDP
  spreadsheet.pedido_mdp = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pedir_mdp || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.pedido_mdp_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pedir_mdp * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  // BA
  spreadsheet.pedido_ba = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pedir_ba || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.pedido_ba_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pedir_ba * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  // TRANSFERIR
  spreadsheet.mdp_pico = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.mdp_pico || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.mdp_pico_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.mdp_pico * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  spreadsheet.ba_pico = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.ba_pico || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.ba_pico_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.ba_pico * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  spreadsheet.pico_mdp = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pico_mdp || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.pico_mdp_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pico_mdp * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  spreadsheet.ba_mdp = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.ba_mdp || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.ba_mdp_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.ba_mdp * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  spreadsheet.pico_ba = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pico_ba || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.pico_ba_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.pico_ba * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  spreadsheet.mdp_ba = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.mdp_ba || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });
  spreadsheet.mdp_ba_m = computed(() => {
    return body.value.reduce(
      (total, line) => {
        var pedir = line.providers.reduce(
          (subTotal, subLine) => {
            var pedirSubline = subLine.mdp_ba * subLine.precio * subLine.coeficiente_precio || 0;
            return subTotal + pedirSubline;
          },
        0);
        return total + pedir;
      },
    0);
  });

  // pedido total
  spreadsheet.p_total_u = computed(() => {
    return spreadsheet.pedido_pico + spreadsheet.pedido_mdp + spreadsheet.pedido_ba;
  });

  spreadsheet.p_total_m = computed(() => {
    return spreadsheet.pedido_pico_m + spreadsheet.pedido_mdp_m + spreadsheet.pedido_ba_m;
  });
}

fetch(`${process.env.VUE_APP_API}orders/${id}`)
  .then( res => res.json() )
  .then( data => {
  // spreadsheet = reactive({ ...data })
  Object.assign(spreadsheet, data)
  body.value = JSON.parse(spreadsheet.body);
  // console.log('1-> ', analisisName.value)
  analisisName.value = spreadsheet.analisis;
  // console.log('2-> ', analisisName.value)
  // console.log(body)
  // console.log(spreadsheet)
  setPedido();
  appState.stopWaiting();
})

// const updateValue = (item) => {
//   // const index = this.items.findIndex(i => i.id === item.id)
//   // this.items.splice(index, 1, item)
//   console.log( 'padre -> updateValue ', item );
//   console.log( 'element ', this );
// }
</script>

<style scoped>
.flex-cell{
  display: flex;
  align-items: center;
}

.col1{
  width: 1em;
  margin-right: 1em;
}
.col2{
  flex-grow: 1;
}
</style>